import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
	{
		path: "/track-report",
		component: () =>
			import(
				/* webpackChunkName: "track-report" */ "../views/open/TrackReport.vue"
			),
	},
	{
		path: "/print-xray-report",
		component: () =>
			import(
				/* webpackChunkName: "print-xray-report" */ "../views/open/PrintXrayReport.vue"
			),
	},
	{
		path: "/print-ecg-report",
		component: () =>
			import(
				/* webpackChunkName: "print-ecg-report" */ "../views/open/PrintECGReport.vue"
			),
	},
	{
		path: "/print-pft-report",
		component: () =>
			import(
				/* webpackChunkName: "print-pft-report" */ "../views/open/PrintPFTReport.vue"
			),
	},
	{
		path: "/print-eeg-report",
		component: () =>
			import(
				/* webpackChunkName: "print-eeg-report" */ "../views/open/PrintEEGReport.vue"
			),
	},
	{
		path: "/print-payment-receipt/:id",
		component: () =>
			import(
				/* webpackChunkName: "print-payment-receipt" */ "../views/open/PrintPaymentReceipt.vue"
			),
	},
	{
		path: "/",
		component: Home,
		children: [
			{
				path: "about",
				component: () =>
					import(/* webpackChunkName: "aboutme" */ "../views/About.vue"),
			},
			{
				path: "table",
				component: () =>
					import(/* webpackChunkName: "table" */ "../views/Table.vue"),
			},
		],
	},
	// admin
	{
		path: "/admin",
		component: () =>
			import(
				/* webpackChunkName: "adminHome" */ "../views/admin/AdminHome.vue"
			),
		redirect: "/admin/dashboard",
		children: [
			{
				path: "dashboard",
				component: () =>
					import(
						/* webpackChunkName: "admin-dashboard" */ "../views/admin/Dashboard.vue"
					),
			},
			{
				path: "center",
				component: () =>
					import(
						/* webpackChunkName: "admin-center" */ "../views/admin/Center.vue"
					),
			},
			{
				path: "center/payment/:id",
				component: () =>
					import(
						/* webpackChunkName: "admin-center-payment" */ "../views/admin/CenterPayment.vue"
					),
			},
			{
				path: "radiologist",
				component: () =>
					import(
						/* webpackChunkName: "admin-radiologist" */ "../views/admin/Radiologist.vue"
					),
			},
			{
				path: "radiologist/payment/:id",
				component: () =>
					import(
						/* webpackChunkName: "admin-radiologist-payment" */ "../views/admin/RadiologistPayment.vue"
					),
			},
			{
				path: "cardiologist",
				component: () =>
					import(
						/* webpackChunkName: "admin-cardiologist" */ "../views/admin/Cardiologist.vue"
					),
			},
			{
				path: "cardiologist/payment/:id",
				component: () =>
					import(
						/* webpackChunkName: "admin-cardiologist-payment" */ "../views/admin/CardiologistPayment.vue"
					),
			},
			{
				path: "pulmonologist",
				component: () =>
					import(
						/* webpackChunkName: "admin-pulmonologist" */ "../views/admin/Pulmonologist.vue"
					),
			},
			{
				path: "pulmonologist/payment/:id",
				component: () =>
					import(
						/* webpackChunkName: "admin-pulmonologist-payment" */ "../views/admin/PulmonologistPayment.vue"
					),
			},
			{
				path: "neurologist",
				component: () =>
					import(
						/* webpackChunkName: "admin-neurologist" */ "../views/admin/Neurologist.vue"
					),
			},
			{
				path: "neurologist/payment/:id",
				component: () =>
					import(
						/* webpackChunkName: "admin-neurologist-payment" */ "../views/admin/NeurologistPayment.vue"
					),
			},
			{
				path: "test",
				component: () =>
					import(
						/* webpackChunkName: "admin-test" */ "../views/admin/Test.vue"
					),
			},
			{
				path: "agent",
				component: () =>
					import(
						/* webpackChunkName: "admin-agent" */ "../views/admin/Agent.vue"
					),
			},
			{
				path: "agent/payment/:id",
				component: () =>
					import(
						/* webpackChunkName: "admin-agent-payment" */ "../views/admin/AgentPayment.vue"
					),
			},
			{
				path: "xray-reporting",
				component: () =>
					import(
						/* webpackChunkName: "admin-xray-reporting" */ "../views/admin/XRayReporting.vue"
					),
			},
			{
				path: "ecg-reporting",
				component: () =>
					import(
						/* webpackChunkName: "admin-ecg-reporting" */ "../views/admin/ECGReporting.vue"
					),
			},
			{
				path: "pft-reporting",
				component: () =>
					import(
						/* webpackChunkName: "admin-pft-reporting" */ "../views/admin/PFTReporting.vue"
					),
			},
			{
				path: "eeg-reporting",
				component: () =>
					import(
						/* webpackChunkName: "admin-eeg-reporting" */ "../views/admin/EEGReporting.vue"
					),
			},
			{
				path: "received",
				component: () =>
					import(
						/* webpackChunkName: "admin-received" */ "../views/admin/Received.vue"
					),
			},
			{
				path: "all-radiologist-payment",
				component: () =>
					import(
						/* webpackChunkName: "admin-all-radiologist-payment" */ "../views/admin/AllRadiologistPayment.vue"
					),
			},
			{
				path: "all-cardiologist-payment",
				component: () =>
					import(
						/* webpackChunkName: "admin-all-cardiologist-payment" */ "../views/admin/AllCardiologistPayment.vue"
					),
			},
			{
				path: "all-pulmonologist-payment",
				component: () =>
					import(
						/* webpackChunkName: "admin-all-pulmonologist-payment" */ "../views/admin/AllPulmonologistPayment.vue"
					),
			},
			{
				path: "all-neurologist-payment",
				component: () =>
					import(
						/* webpackChunkName: "admin-all-neurologist-payment" */ "../views/admin/AllNeurologistPayment.vue"
					),
			},
			{
				path: "xray-uploading",
				component: () =>
					import(
						/* webpackChunkName: "admin-xray-uploading" */ "../views/admin/XRayUploading.vue"
					),
			},
			{
				path: "ecg-uploading",
				component: () =>
					import(
						/* webpackChunkName: "admin-ecg-uploading" */ "../views/admin/ECGUploading.vue"
					),
			},
			{
				path: "pft-uploading",
				component: () =>
					import(
						/* webpackChunkName: "admin-pft-uploading" */ "../views/admin/PFTUploading.vue"
					),
			},
			{
				path: "eeg-uploading",
				component: () =>
					import(
						/* webpackChunkName: "admin-eeg-uploading" */ "../views/admin/EEGUploading.vue"
					),
			},
			{
				path: "transaction-audit",
				component: () =>
					import(
						/* webpackChunkName: "admin-transaction-audit" */ "../views/admin/TransactionAudit.vue"
					),
			},

			{
				path: "xray-overview",
				component: () =>
					import(
						/* webpackChunkName: "admin-xray-overview" */ "../views/admin/XrayOverview.vue"
					),
			},

			{
				path: "payment-overview",
				component: () =>
					import(
						/* webpackChunkName: "admin-payment-overview" */ "../views/admin/PaymentOverview.vue"
					),
			},


			

			
			
			{
				path: "default-rates",
				component: () =>
					import(
						/* webpackChunkName: "admin-default-rates" */ "../views/admin/DefaultRates.vue"
					),
			},
			{
				path: "notice",
				component: () =>
					import(
						/* webpackChunkName: "admin-notice" */ "../views/admin/Notice.vue"
					),
			},
			{
				path: "reviews",
				component: () =>
					import(
						/* webpackChunkName: "admin-reviews" */ "../views/admin/Review.vue"
					),
			},
			{
				path: "options",
				component: () =>
					import(
						/* webpackChunkName: "admin-options" */ "../views/admin/Options.vue"
					),
			},
			
		],
	},
	// center
	{
		path: "/center",
		component: () =>
			import(
				/* webpackChunkName: "centerHome" */ "../views/center/CenterHome.vue"
			),
		redirect: "/center/dashboard",
		children: [
			{
				path: "dashboard",
				component: () =>
					import(
						/* webpackChunkName: "center-dashboard" */ "../views/center/Dashboard.vue"
					),
			},
			{
				path: "add-patient",
				component: () =>
					import(
						/* webpackChunkName: "center-add-patient" */ "../views/center/AddPatient.vue"
					),
			},
			{
				path: "xray-report",
				component: () =>
					import(
						/* webpackChunkName: "center-xray-report" */ "../views/center/XrayReport.vue"
					),
			},
			{
				path: "print-xray-report/:reportId",
				component: () =>
					import(
						/* webpackChunkName: "center-print-xray-report" */ "../views/center/PrintXrayReport.vue"
					),
			},
			{
				path: "ecg-report",
				component: () =>
					import(
						/* webpackChunkName: "center-ecg-report" */ "../views/center/ECGReport.vue"
					),
			},
			{
				path: "print-ecg-report/:reportId",
				component: () =>
					import(
						/* webpackChunkName: "center-print-ecg-report" */ "../views/center/PrintECGReport.vue"
					),
			},
			{
				path: "pft-report",
				component: () =>
					import(
						/* webpackChunkName: "center-pft-report" */ "../views/center/PFTReport.vue"
					),
			},
			{
				path: "eeg-report",
				component: () =>
					import(
						/* webpackChunkName: "center-eeg-report" */ "../views/center/EEGReport.vue"
					),
			},
			{
				path: "payment",
				component: () =>
					import(
						/* webpackChunkName: "center-payment" */ "../views/center/Payment.vue"
					),
			},
			{
				path: "billing-xray",
				component: () =>
					import(
						/* webpackChunkName: "center-billing-xray" */ "../views/center/BillingXray.vue"
					),
			},
			{
				path: "billing-ecg",
				component: () =>
					import(
						/* webpackChunkName: "center-billing-ecg" */ "../views/center/BillingEcg.vue"
					),
			},
			{
				path: "billing-pft",
				component: () =>
					import(
						/* webpackChunkName: "center-billing-pft" */ "../views/center/BillingPFT.vue"
					),
			},
			{
				path: "billing-eeg",
				component: () =>
					import(
						/* webpackChunkName: "center-billing-eeg" */ "../views/center/BillingEeg.vue"
					),
			},
			{
				path: "general-settings",
				component: () =>
					import(
						/* webpackChunkName: "center-general-settings" */ "../views/center/GeneralSettings.vue"
					),
			},
			{
				path: "rate-chart",
				component: () =>
					import(
						/* webpackChunkName: "rate-chart" */ "../views/center/RateChart.vue"
					),
			},
			{
				path: "contact-admin",
				component: () =>
					import(
						/* webpackChunkName: "contact-admin" */ "../views/center/ContactAdmin.vue"
					),
			},
			{
				path: "prefer-radiologist",
				component: () =>
					import(
						/* webpackChunkName: "prefer-radiologist" */ "../views/center/PreferRadiologist.vue"
					),
			},
			{
				path: "prefer-cardiologist",
				component: () =>
					import(
						/* webpackChunkName: "prefer-cardiologist" */ "../views/center/PreferCardiologist.vue"
					),
			},
			{
				path: "prefer-pulmonologist",
				component: () =>
					import(
						/* webpackChunkName: "prefer-pulmonologist" */ "../views/center/PreferPulmonologist.vue"
					),
			},
			{
				path: "prefer-neurologist",
				component: () =>
					import(
						/* webpackChunkName: "prefer-neurologist" */ "../views/center/PreferNeurologist.vue"
					),
			},
		],
	},
	// radiologist
	{
		path: "/radiologist",
		component: () =>
			import(
				/* webpackChunkName: "radiologistHome" */ "../views/radiologist/RadiologistHome.vue"
			),
		redirect: "/radiologist/dashboard",
		children: [
			{
				path: "dashboard",
				component: () =>
					import(
						/* webpackChunkName: "radiologist-dashboard" */ "../views/radiologist/Dashboard.vue"
					),
			},
			{
				path: "reports/:page",
				component: () =>
					import(
						/* webpackChunkName: "radiologist-reports" */ "../views/radiologist/Reports.vue"
					),
			},
			{
				path: "report/:report_id",
				component: () =>
					import(
						/* webpackChunkName: "radiologist-report" */ "../views/radiologist/Report.vue"
					),
			},
			{
				path: "print-xray-report/:reportId",
				component: () =>
					import(
						/* webpackChunkName: "radiologist-print-xray-report" */ "../views/radiologist/PrintXrayReport.vue"
					),
			},
			{
				path: "templates",
				component: () =>
					import(
						/* webpackChunkName: "radiologist-templates" */ "../views/radiologist/Templates.vue"
					),
			},
			{
				path: "billing",
				component: () =>
					import(
						/* webpackChunkName: "radiologist-billing" */ "../views/radiologist/Billing.vue"
					),
			},
			{
				path: "my-received",
				component: () =>
					import(
						/* webpackChunkName: "radiologist-my-received" */ "../views/radiologist/myReceived.vue"
					),
			},
		],
	},
	// cardiologist
	{
		path: "/cardiologist",
		component: () =>
			import(
				/* webpackChunkName: "cardiologistHome" */ "../views/cardiologist/CardiologistHome.vue"
			),
		redirect: "/cardiologist/dashboard",
		children: [
			{
				path: "dashboard",
				component: () =>
					import(
						/* webpackChunkName: "cardiologist-dashboard" */ "../views/cardiologist/Dashboard.vue"
					),
			},
			{
				path: "reports/:page",
				component: () =>
					import(
						/* webpackChunkName: "cardiologist-reports" */ "../views/cardiologist/Reports.vue"
					),
			},
			{
				path: "report/:report_id",
				component: () =>
					import(
						/* webpackChunkName: "cardiologist-report" */ "../views/cardiologist/Report.vue"
					),
			},
			{
				path: "print-ecg-report/:reportId",
				component: () =>
					import(
						/* webpackChunkName: "cardiologist-print-ecg-report" */ "../views/cardiologist/PrintECGReport.vue"
					),
			},
			{
				path: "templates",
				component: () =>
					import(
						/* webpackChunkName: "cardiologist-templates" */ "../views/cardiologist/Templates.vue"
					),
			},
			{
				path: "billing",
				component: () =>
					import(
						/* webpackChunkName: "cardiologist-billing" */ "../views/cardiologist/Billing.vue"
					),
			},
			{
				path: "my-received",
				component: () =>
					import(
						/* webpackChunkName: "cardiologist-my-received" */ "../views/cardiologist/myReceived.vue"
					),
			},
		],
	},
	// pulmonologist
	{
		path: "/pulmonologist",
		component: () =>
			import(
				/* webpackChunkName: "pulmonologistHome" */ "../views/pulmonologist/PulmonologistHome.vue"
			),
		redirect: "/pulmonologist/dashboard",
		children: [
			{
				path: "dashboard",
				component: () =>
					import(
						/* webpackChunkName: "pulmonologist-dashboard" */ "../views/pulmonologist/Dashboard.vue"
					),
			},
			{
				path: "reports/:page",
				component: () =>
					import(
						/* webpackChunkName: "pulmonologist-reports" */ "../views/pulmonologist/Reports.vue"
					),
			},
			{
				path: "report/:report_id",
				component: () =>
					import(
						/* webpackChunkName: "pulmonologist-report" */ "../views/pulmonologist/Report.vue"
					),
			},
			{
				path: "print-ecg-report/:reportId",
				component: () =>
					import(
						/* webpackChunkName: "pulmonologist-print-ecg-report" */ "../views/pulmonologist/PrintPFTReport.vue"
					),
			},
			{
				path: "templates",
				component: () =>
					import(
						/* webpackChunkName: "pulmonologist-templates" */ "../views/pulmonologist/Templates.vue"
					),
			},
			{
				path: "billing",
				component: () =>
					import(
						/* webpackChunkName: "pulmonologist-billing" */ "../views/pulmonologist/Billing.vue"
					),
			},
			{
				path: "my-received",
				component: () =>
					import(
						/* webpackChunkName: "pulmonologist-my-received" */ "../views/pulmonologist/myReceived.vue"
					),
			},
		],
	},
	// neurologist
	{
		path: "/neurologist",
		component: () =>
			import(
				/* webpackChunkName: "neurologistHome" */ "../views/neurologist/NeurologistHome.vue"
			),
		redirect: "/neurologist/dashboard",
		children: [
			{
				path: "dashboard",
				component: () =>
					import(
						/* webpackChunkName: "neurologist-dashboard" */ "../views/neurologist/Dashboard.vue"
					),
			},
			{
				path: "reports/:page",
				component: () =>
					import(
						/* webpackChunkName: "neurologist-reports" */ "../views/neurologist/Reports.vue"
					),
			},
			{
				path: "report/:report_id",
				component: () =>
					import(
						/* webpackChunkName: "neurologist-report" */ "../views/neurologist/Report.vue"
					),
			},
			{
				path: "templates",
				component: () =>
					import(
						/* webpackChunkName: "neurologist-templates" */ "../views/neurologist/Templates.vue"
					),
			},
			{
				path: "billing",
				component: () =>
					import(
						/* webpackChunkName: "neurologist-billing" */ "../views/neurologist/Billing.vue"
					),
			},
			{
				path: "my-received",
				component: () =>
					import(
						/* webpackChunkName: "neurologist-my-received" */ "../views/neurologist/myReceived.vue"
					),
			},
		],
	},
	// agent
	{
		path: "/agent",
		component: () =>
			import(
				/* webpackChunkName: "agentHome" */ "../views/agent/AgentHome.vue"
			),
		redirect: "/agent/dashboard",
		children: [
			{
				path: "dashboard",
				component: () =>
					import(
						/* webpackChunkName: "agent-dashboard" */ "../views/agent/Dashboard.vue"
					),
			},
			{
				path: "xray-reports",
				component: () =>
					import(
						/* webpackChunkName: "agent-xray-reports" */ "../views/agent/XrayReports.vue"
					),
			},
			{
				path: "ecg-reports",
				component: () =>
					import(
						/* webpackChunkName: "agent-ecg-reports" */ "../views/agent/EcgReports.vue"
					),
			},
			{
				path: "pft-reports",
				component: () =>
					import(
						/* webpackChunkName: "agent-pft-reports" */ "../views/agent/PFTReports.vue"
					),
			},
			{
				path: "eeg-reports",
				component: () =>
					import(
						/* webpackChunkName: "agent-eeg-reports" */ "../views/agent/EEGReports.vue"
					),
			},

			
			{
				path: "center-details",
				component: () =>
					import(
						/* webpackChunkName: "agent-center-details" */ "../views/agent/CenterDetails.vue"
					),
			},

			{
				path: "xray-uploading",
				component: () =>
					import(
						/* webpackChunkName: "agent-xray-uploading" */ "../views/agent/XRayUploading.vue"
					),
			},

			{
				path: "my-received",
				component: () =>
					import(
						/* webpackChunkName: "agent-my-received" */ "../views/agent/myReceived.vue"
					),
			},
		],
	},
	{
		path: "/about",
		name: "About",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "aboutme" */ "../views/About.vue"),
	},
	{
		path: "/admin-login",
		component: () =>
			import(/* webpackChunkName: "adminLogin" */ "../views/AdminLogin.vue"),
	},
	{
		path: "/center-login",
		component: () =>
			import(/* webpackChunkName: "centerLogin" */ "../views/CenterLogin.vue"),
	},
	{
		path: "/radiologist-login",
		component: () =>
			import(
				/* webpackChunkName: "radiologistLogin" */ "../views/RadiologistLogin.vue"
			),
	},
	{
		path: "/cardiologist-login",
		component: () =>
			import(
				/* webpackChunkName: "cardiologistLogin" */ "../views/CardiologistLogin.vue"
			),
	},
	{
		path: "/pulmonologist-login",
		component: () =>
			import(
				/* webpackChunkName: "pulmonologistLogin" */ "../views/PulmonologistLogin.vue"
			),
	},
	{
		path: "/neurologist-login",
		component: () =>
			import(
				/* webpackChunkName: "neurologistLogin" */ "../views/NeurologistLogin.vue"
			),
	},
	{
		path: "/agent-login",
		component: () =>
			import(/* webpackChunkName: "agentLogin" */ "../views/AgentLogin.vue"),
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
